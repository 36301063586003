import React, { useEffect, useState } from "react";
import { Image, Form, Button, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import * as yup from "yup";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import 'react-select/dist/react-select.css';
import ContactIcon from "../../images/auth-left.svg";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import SuccessIcon from "../../images/svg1.svg";
import { Country, State, City } from "country-state-city";
import { API_URL } from "../../../utils/API_URL";
import { Icon } from "@iconify/react";
import Icon1 from "../../images/student.svg";
import Icon2 from "../../images/admissionofficer.svg";
import {
  Helper,
  LocalStorage,
  validateEmail,
  validateFields,
} from "../../../utils/HelperFunction";
import { fetchMajor } from "../../../store/majorSlice";
import { UpdateUser } from "../../../store/userSlice";
const RadioButton = ({ text, icon, checked, onChange }) => {
  return (
    <label
      className="radionsignup"
      style={{ borderColor: checked ? "#7B76F1" : "#D8D6DE" }}
    >
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{ display: "none" }}
      />
      <Image width={40} height={40} src={icon} />
      <span style={{ marginTop: "4px", color: checked ? "#7B76F1" : "black" }}>
        {text}
      </span>
      {checked && <Icon icon="lets-icons:check-fill" />}
    </label>
  );
};

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  password: "",
  country_code: "",
  state_code: "",
  school_name: "",
  school_id: "",
  graduation_year: "",
  gpa: "",
  sat_act_scores: "",
  position: "",
  linkedin_profile: "",
  is_officer: false,
  major: [],
  invite_code: "",
};
const defaultOptValues = {
  confirm_password: "",
  policy: false,
  invite: false,
};
const defaultErrValues = {
  first_name: false,
  last_name: false,
  email: false,
  phone_number: false,
  country: false,
  state: false,
  city: false,
  zip_code: false,
  password: false,
  confirm_password: false,
  policy: false,
  school_id: false,
  school_name: false,
  gpa: false,
  sat_act_scores: false,
  position: false,
  linkedin_profile: false,
  graduation_year: false,
  major: false,
  invite_code: false,
};
const signupSchema = yup.object().shape({
  is_officer: yup.boolean().required(),
  first_name: yup.string().required("Enter first name"),
  last_name: yup.string().required("Enter last name"),
  email: yup.string().email("Invalid Email Format").required("Enter email"),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .min(10, "Mobile number must be 10 digits")
    .max(10, "Mobile number must be 10 digits"),
  city: yup.string().required("Select a city"),
  state: yup.string().required("Select a state"),
  country: yup.string().required("Select a country"),
  zip_code: yup.string().required("Enter zip code"),

  // school_id: yup.string().required("Enter school id"),
  // school_name: yup.string().required("Enter school name"),
  school_id: yup.string().when("is_officer", {
    is: (val) => val === false,
    then: (s) => s.nullable(),
    otherwise: (s) => s.min(1).required("Enter school id"),
  }),
  school_name: yup.string().when("is_officer", {
    is: (val) => val === false,
    then: (s) => s.nullable(),
    otherwise: (s) => s.min(1).required("Enter school name"),
  }),
  gpa: yup.string().nullable(),
  sat_act_scores: yup.string().nullable(),
  position: yup.string().nullable(),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_#*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "The password must be atleast 8 characters long and include one lowercase letter, one uppercase letter, one digit, and one special characters."
    )
    .required("Enter password"),
  graduation_year: yup.string().when("is_officer", {
    is: (val) => val,
    then: (s) => s.nullable(),
    otherwise: (s) => s.required("Select graduation year"),
  }),
  major: yup.array().when("is_officer", {
    is: (val) => val,
    then: (s) => s.nullable(),
    otherwise: (s) => s.min(1).required("Select major"),
  }),
  invite_code: yup.string().when("is_officer", {
    is: (val) => val,
    then: (s) => s.nullable(),
    otherwise: (s) => s.min(1).required("Enter Invite Code"),
  }),
});
const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Major } = useSelector((state) => state.Major);
  const [formObj, setFormObj] = useState(defaultValues);
  const [optObj, setOptObj] = useState(defaultOptValues);
  const [errorObj, setErrorObj] = useState(defaultErrValues);
  const [country, setCountry] = useState(Country.getAllCountries());
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  // [
  //   { value: "Major 1", label: "Major 1" },
  //   { value: "Major 2", label: "Major 2" },
  //   { value: "Major 3", label: "Major 3" },
  //   { value: "Major 4", label: "Major 4" },
  // ];
  // const [schoolList, setSchoolList] = useState(null);

  useEffect(() => {
    // fetchSchool();
    // dispatch(fetchMajor());
  }, []);
  useEffect(() => {
    if (Major?.length > 0) {
      const data = Major?.map((major) => ({
        label: major.name,
        value: major._id,
      }));
      setOptions(data);
    }
  }, [Major]);
  // const fetchSchool = async () => {
  //   try {
  //     const url = API_URL.SCHOOL_LIST;
  //     const result = await Helper.GetData(url);
  //     setSchoolList(result.data?.data?.result || []);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleOptionChange = (option) => {
    setFormObj((prev) => ({ ...prev, is_officer: option }));
    setErrorObj(defaultErrValues);
  };

  const isDisabled = () => {
    if (
      !formObj.city ||
      !formObj.country ||
      !formObj.first_name ||
      !formObj.last_name ||
      !formObj.email ||
      !formObj.phone_number ||
      !formObj.password ||
      !formObj.city ||
      !optObj.confirm_password ||
      !formObj.zip_code ||
      !optObj.policy ||
      (formObj.is_officer && !formObj.school_id) ||
      (formObj.is_officer && !formObj.school_name) ||
      (!formObj.is_officer && !formObj.graduation_year) ||
      (!formObj.is_officer && formObj.major.length < 1) ||
      (!formObj.is_officer && !formObj.invite_code)
    ) {
      return {
        disabled: true,
      };
    }
    return {
      disabled: false,
    };
  };
  const handleInputChange = (event) => {
    setErrorObj(defaultErrValues);
    const { name, value } = event.target;
    if (name === "country") {
      const countryInput = value.split("&");
      setFormObj((prev) => ({
        ...prev,
        country: countryInput[1],
        country_code: countryInput[0],
      }));
      setState(() => State.getStatesOfCountry(countryInput[0]));
    } else if (name === "state") {
      const stateInput = value.split("&");
      setFormObj((prev) => ({
        ...prev,
        state: stateInput[1],
        state_code: stateInput[0],
      }));
      setCity(() => City.getCitiesOfState(formObj.country_code, stateInput[0]));
    } else if (name === "city") {
      setFormObj((prev) => ({ ...prev, city: value }));
    } else if (name === "confirm_password") {
      setOptObj((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "policy" || name === "invite") {
      setOptObj((prev) => ({
        ...prev,
        [name]: event.target.checked,
      }));
    } else {
      setFormObj({
        ...formObj,
        [name]: value.trimStart(),
      });
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const errors = await validateFields(signupSchema, formObj);
      if (Object.keys(errors).length > 0) {
        setErrorObj(errors);
        return;
      }

      if (formObj.linkedin_profile) {
        const linkedinRegex =
          /^https?:\/\/(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+\/?$/;
        const isValidUrl = linkedinRegex.test(formObj.linkedin_profile);
        // console.log(88, isValidUrl);
        // if (!isValidUrl) {
        //   setLoading(false);
        //   return setErrorObj((prev) => ({
        //     ...prev,
        //     linkedin_profile: "Please enter valid linkedIn profile",
        //   }));
        // }
      }
      // const isVaildEmail = await validateEmail(formObj.email);
      // console.log({isVaildEmail});
      // if (isVaildEmail) {
      //   return setErrorObj((prev) => ({ ...prev, email: isVaildEmail.email }));
      // }

      if (formObj.password !== optObj.confirm_password) {
        return setErrorObj((prev) => ({ ...prev, confirm_password: true }));
      }
      if (optObj.policy === false) {
        return setErrorObj((prev) => ({ ...prev, policy: true }));
      }
      if (optObj.invite && !formObj.invite_code) {
        return setErrorObj((prev) => ({
          ...prev,
          invite_code: "Enter invite code",
        }));
      }
      const url = API_URL.REGISTER;
      const obj = {
        is_officer: formObj.is_officer,
        first_name: formObj.first_name,
        last_name: formObj.last_name,
        email: formObj.email,
        phone_number: formObj.phone_number,
        country: formObj.country,
        state: formObj.state,
        city: formObj.city,
        zip_code: formObj.zip_code,
        password: formObj.password,
        country_code: formObj.country_code,
        state_code: formObj.state_code,
        ...(formObj.school_name && { school_name: formObj.school_name }),
        ...(formObj.school_id && { school_id: formObj.school_id }),
        ...(!formObj.is_officer && {
          graduation_year: formObj.graduation_year,
          gpa: formObj.gpa,
          sat_act_scores: formObj.sat_act_scores,
          major: formObj.major.map((val) => val.value),
        }),
        ...(formObj.is_officer && {
          position: formObj.position,
          linkedin_profile: formObj.linkedin_profile,
        }),
        ...(formObj.invite_code && {
          invite_token: formObj.invite_code,
        }),
      };
      const result = await Helper.PostData(url, obj);
      if (result.data?.status) {
        if (formObj.invite_code) {
          LocalStorage.set("token", result.data.data.token);
          LocalStorage.set("refreshToken", result.data.data.refreshToken);
          dispatch(
            UpdateUser({ ...result.data.data, token: result.data.data.token })
          );
          window.location.replace("/");
        } else {
          sessionStorage.setItem("validEntry", "true");
          navigate(`/otp?key=${result.data.data.email}&type=SIGNUP`);
        }
      } else if (
        Array.isArray(result.response?.data?.error) &&
        result.data?.error.indexOf("email must be a valid email") !== -1
      ) {
        toast.error("Email must be a valid email.");
      } else if (result.data?.message) {
        toast.error(result.data.message);
      } else if (
        result.response?.status === 400 &&
        result.response.data.type === "email"
      ) {
        setErrorObj((prev) => ({
          ...prev,
          email: result.response.data.message,
        }));
      } else if (result.response && result.response.data) {
        toast.error(result.response.data.message);
      } else {
        toast.error(result.response.data.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("Server not connected");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (selected) => {
    console.log(selected);
    setErrorObj(defaultErrValues);
    if (selected.length < 6) {
      setFormObj((prev) => ({ ...prev, major: selected }));
    } else {
      setErrorObj((prev) => ({
        ...prev,
        major: "You can select upto 5 majors",
      }));
    }
  };
  // console.log(555, options);
  return (
    <React.Fragment>
      <section className="signinSection">
        <div className="cleftside w49">
          <Image src={ContactIcon} alt="" className="img-fluid" />
          <h2>
            Discover, Connect, Succeed: <br />
            Your Social Network for College Prep
          </h2>
          <p style={{ marginBottom: "5px" }}>
            Embark on your college journey with us: a place to connect, uncover
            insights, and excel in your prep.{" "}
          </p>
          <p>
            It's all about teamwork, sharing experiences, and achieving dreams
            together!
          </p>
        </div>

        <div className="crightside w49">
          <div className="loginHeading mb-4">
            <h1>
              Create an <strong>Account</strong>
            </h1>
            <p className="mb-0">
              To get started, please enter your details below.
            </p>
          </div>
          <div className="d-flex justify-content-center mb-4 mt-3">
            <RadioButton
              key="option1"
              text="Student"
              icon={Icon1}
              checked={!formObj.is_officer}
              onChange={() => handleOptionChange(false)}
            />
            <RadioButton
              key="option2"
              text="Admissions Officer"
              icon={Icon2}
              checked={formObj.is_officer}
              onChange={() => handleOptionChange(true)}
            />
          </div>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  value={formObj.first_name}
                  type="text"
                  placeholder="Enter First Name"
                  name="first_name"
                  onChange={handleInputChange}
                />
                {errorObj.first_name && (
                  <div className="error">{errorObj.first_name}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  value={formObj.last_name}
                  type="text"
                  placeholder="Enter Last Name"
                  name="last_name"
                  onChange={handleInputChange}
                />
                {errorObj.last_name && (
                  <div className="error">{errorObj.last_name}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {formObj.is_officer && "Work"} Email Address
                </Form.Label>
                <Form.Control
                  value={formObj.email}
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  onChange={handleInputChange}
                />
                {errorObj.email && (
                  <div className="error">{errorObj.email}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={formObj.phone_number}
                  type="text"
                  placeholder="Enter Phone Number"
                  maxLength="10"
                  minLength="10"
                  name="phone_number"
                  onChange={handleInputChange}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
                {errorObj.phone_number && (
                  <div className="error">{errorObj.phone_number}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  value={`${formObj.country_code}&${formObj.country}`}
                  name="country"
                  onChange={handleInputChange}
                >
                  <option value="">Select Country</option>
                  {country?.map((cnt) => {
                    return (
                      <option
                        value={`${cnt.isoCode}&${cnt.name}`}
                        key={cnt.isoCode}
                      >
                        {cnt.name}
                      </option>
                    );
                  })}
                </Form.Select>
                {errorObj.country && (
                  <div className="error">{errorObj.country}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <Form.Select
                  value={`${formObj.state_code}&${formObj.state}`}
                  name="state"
                  onChange={handleInputChange}
                >
                  <option value="">Select State</option>
                  {state?.map((stt) => {
                    return (
                      <option
                        value={`${stt.isoCode}&${stt.name}`}
                        key={stt.isoCode}
                      >
                        {stt.name}
                      </option>
                    );
                  })}
                </Form.Select>
                {errorObj.state && (
                  <div className="error">{errorObj.state}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Select
                  value={formObj.city}
                  name="city"
                  onChange={handleInputChange}
                >
                  <option value="">Select City</option>
                  {city?.map((city) => {
                    return (
                      <option value={city.name} key={city.name}>
                        {city.name}
                      </option>
                    );
                  })}
                </Form.Select>
                {errorObj.city && <div className="error">{errorObj.city}</div>}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  value={formObj.zip_code}
                  type="text"
                  placeholder="Zip Code"
                  name="zip_code"
                  onChange={handleInputChange}
                />
                {errorObj.zip_code && (
                  <div className="error">{errorObj.zip_code}</div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row key="option1Content">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {formObj.is_officer ? "Institute" : "School"} Name
                </Form.Label>
                <Form.Control
                  value={formObj.school_name}
                  name="school_name"
                  type="text"
                  placeholder={`Enter ${
                    formObj.is_officer ? "institute" : "school"
                  } name`}
                  onChange={handleInputChange}
                />
                {errorObj.school_name && (
                  <div className="error">{errorObj.school_name}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {formObj.is_officer ? "Institute" : "School"} ID Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`Enter ${
                    formObj.is_officer ? "institute" : "school"
                  } id number`}
                  name="school_id"
                  value={formObj.school_id}
                  onChange={handleInputChange}
                />
                {errorObj.school_id && (
                  <div className="error">{errorObj.school_id}</div>
                )}
              </Form.Group>
            </Col>
            {!formObj.is_officer && (
              <>
                <Col lg={12} className="mb-3">
                  <Form.Label>Graduation Year</Form.Label>
                  <Datetime
                    dateFormat="YYYY"
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Year", readOnly: true }}
                    value={
                      formObj.graduation_year
                        ? new Date(formObj.graduation_year, 0, 1)
                        : ""
                    }
                    closeOnSelect={true}
                    viewMode="years"
                    onChange={(date) =>
                      setFormObj((prev) => ({
                        ...prev,
                        graduation_year: date.year()?.toString(),
                      }))
                    }
                  />
                  {errorObj.graduation_year && (
                    <div className="error">{errorObj.graduation_year}</div>
                  )}
                </Col>
                <Col lg={12} className="mb-3">
                  <Form.Label>Major</Form.Label>
                  <Select
                    isMulti
                    name="major"
                    options={options}
                    value={formObj.major}
                    onChange={handleChange}
                    styles={{
                      control: (val) => ({
                        ...val,
                        minHeight: "50px",
                        borderRadius: "12px",
                      }),
                    }}
                  />
                  {errorObj.major && (
                    <div className="error">{errorObj.major}</div>
                  )}
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>GPA (optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter GPA"
                      value={formObj.gpa}
                      name="gpa"
                      onChange={handleInputChange}
                    />
                    {errorObj.gpa && (
                      <div className="error">{errorObj.gpa}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>SAT/ACT Scores (optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter SAT/ACT scores"
                      name="sat_act_scores"
                      value={formObj.sat_act_scores}
                      onChange={handleInputChange}
                    />
                    {errorObj.sat_act_scores && (
                      <div className="error">{errorObj.sat_act_scores}</div>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            {/* {!formObj.is_officer && (
              
            )}
            {!formObj.is_officer && (
              <>
               
              </>
            )} */}
            {/* </Row> */}

            {formObj.is_officer && (
              <Row key="option2Content">
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter position"
                      name="position"
                      onChange={handleInputChange}
                      value={formObj.position}
                    />
                    {errorObj.position && (
                      <div className="error">{errorObj.position}</div>
                    )}
                  </Form.Group>
                </Col>
                {/* <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Years of Experience</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter"
                      name="experience"
                    />
                  </Form.Group>
                </Col> */}
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>LinkedIn Profile (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter linkedin profile"
                      name="linkedin_profile"
                      value={formObj.linkedin_profile}
                      onChange={handleInputChange}
                    />
                    {errorObj.linkedin_profile && (
                      <div className="error">{errorObj.linkedin_profile}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
            {/* <Row> */}
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <div className="passwordPos">
                  <Form.Control
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="Enter Password"
                    value={formObj.password}
                    autoComplete="new-password"
                    name="password"
                    onChange={handleInputChange}
                  />
                  {isRevealPwd ? (
                    <IoMdEye
                      color="#3e3c43"
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  ) : (
                    <IoMdEyeOff
                      color="#3e3c43"
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                  )}
                </div>
                {errorObj.password && (
                  <div className="error">{errorObj.password}</div>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <div className="passwordPos">
                  <Form.Control
                    type={isRevealPwd1 ? "text" : "password"}
                    placeholder="Enter Confirm Password"
                    value={optObj.confirm_password}
                    name="confirm_password"
                    onChange={handleInputChange}
                  />
                  {isRevealPwd1 ? (
                    <IoMdEye
                      color="#3e3c43"
                      onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                    />
                  ) : (
                    <IoMdEyeOff
                      color="#3e3c43"
                      onClick={() => setIsRevealPwd1((prevState) => !prevState)}
                    />
                  )}
                </div>
                {errorObj.confirm_password && (
                  <div className="error">
                    {"Password and confirm password do not match."}
                  </div>
                )}
              </Form.Group>
            </Col>
            {!formObj.is_officer && (
              <Col lg={6} className="mb-3">
                <Form.Check
                  type="checkbox"
                  id={1}
                  label="Referral Code"
                  name="invite"
                  checked={optObj.invite}
                  onChange={handleInputChange}
                  className="d-flex align-items-center"
                  style={{ fontSize: "14px" }}
                />

                {optObj.invite && (
                  <Form.Group>
                    <div className="passwordPos">
                      <Form.Control
                        placeholder="Referral code"
                        value={formObj.invite_code}
                        name="invite_code"
                        onChange={handleInputChange}
                      />
                    </div>
                  </Form.Group>
                )}
                {errorObj.invite_code && (
                  <div className="error">{errorObj.invite_code}</div>
                )}
              </Col>
            )}
          </Row>

          <div className="forFlex mb-3">
            {["checkbox"].map((type) => (
              <div key={`default-${type}`}>
                <Form.Check // prettier-ignore
                  type={type}
                  id={1}
                  label={
                    <span>
                      I agree with{" "}
                      <Link to="/privacypolicy">Privacy Policy</Link> &{" "}
                      <Link to="/termsconditions">Terms & Conditions</Link>
                    </span>
                  }
                  name="policy"
                  checked={optObj.policy}
                  onChange={handleInputChange}
                  className="d-flex align-items-center"
                />
              </div>
            ))}
            {errorObj.policy && (
              <div className="error">{"Please ccept privacy policy"}</div>
            )}
          </div>

          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isDisabled().disabled}
            style={{
              opacity: isDisabled().disabled ? 0.5 : 1,
            }}
          >
            {loading ? "Signing up..." : "Signup"}
          </Button>
          <div className="footBox">
            <p>
              Already have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </section>
      <AccountCreatedSuccessfully
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </React.Fragment>
  );
};

function AccountCreatedSuccessfully(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal_css"
    >
      <Modal.Body>
        <div className="modal_Inner">
          <Image src={SuccessIcon} alt="" className="img-fluid" />
          <h1>
            OTP sent to your email <strong>Successfully</strong>
          </h1>
          <p>
            Congratulations! Your account creation with BRIDDG has been
            successfully initiated.
          </p>
        </div>
        <div className="modal_footer">
          <Button variant="primary" as={Link} to="/Login">
            Login Now
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SignUp;
